import { createSlice } from "@reduxjs/toolkit";

export const uiSlice = createSlice({
  name: "ui",
  initialState: {
    showSideBar: true,
    heighLightCategory: "",
    mobileShowSideBar: true,
    selectMarketScore: false,
    selectYieldModel: false,
    showModal: false,
    hideOnScrollDown: false,
    linkMenus: {
      showWeather: false,
      showCrops: false,
      showTrade: false,
      showPolicies: false,
      showProcessingCapacity: false,
      showPrices: false,
      showPriceAnalysis: false,
      showSentiment: false,
      showTheBigPicture: false,
      showReportArchive: false,
      showGini: false,
      showSnd: false,
      showInfo: false,
    },
    listwrappers: {
      highlightWeather: true,
      highlightCrop: false,
      highlightSnd: false,
      highlightTrade: false,
      highlightAgri: false,
      highlightPrices: false,
      highlightPriceAnalysis: false,
      highlightPicture: false,
      highlightData: false,
    },
    selectedIndex: 0,
  },
  reducers: {
    setShowSideBar: (state, action) => {
      state.showSideBar = action.payload;
    },
    setheighLightCategory: (state, action) => {
      state.heighLightCategory = action.payload;
    },
    setmobileShowSideBar: (state, action) => {
      state.mobileShowSideBar = action.payload;
    },
    setlinkMenus: (state, { payload }) => {
      state.linkMenus = { ...state.linkMenus, ...payload };
    },
    setListwrappers: (state, { payload }) => {
      state.listwrappers = { ...state.listwrappers, ...payload };
    },
    setSelectedIndex: (state, action) => {
      state.selectedIndex = action.payload;
    },
    setSelectMarketScore: (state, action) => {
      state.selectMarketScore = action.payload;
    },
    setSelectYieldModel: (state, action) => {
      state.selectYieldModel = action.payload;
    },
    setShowModal: (state, action) => {
      state.showModal = action.payload;
    },
    setHideOnScrollDown: (state, action) => {
      state.hideOnScrollDown = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setShowSideBar,
  setheighLightCategory,
  setmobileShowSideBar,
  setlinkMenus,
  setListwrappers,
  setSelectedIndex,
  setSelectMarketScore,
  setSelectYieldModel,
  setShowModal,
  setHideOnScrollDown,
} = uiSlice.actions;

export default uiSlice.reducer;
