/* eslint-disable react/jsx-curly-newline */
/* eslint-disable no-confusing-arrow */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable implicit-arrow-linebreak */
import React, { useEffect, useState } from "react";

// Third party
import { Outlet, Navigate, useLocation, useNavigate } from "react-router-dom";

// Redux
import { useDispatch } from "react-redux";

// Api
import useAxios from "./Hooks/useAxios";

//Reducer
import { setSubCategory } from "./Store/Reducer/Category/category";

// Helper
import { getAuth } from "./Utils/index";

const PrivteRoutes = ({ component: Component, ...rest }) => {
  let change_password = JSON.parse(localStorage.getItem("cp"));
  let first_login;

  try {
    first_login = JSON.parse(localStorage.getItem("fl"));
  } catch (err) {
    first_login = localStorage.getItem("fl");
  }

  const [isAuthenticated, setIsAuthenticated] = useState(null);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const api = useAxios();

  const callIsTrialEnd = (signal) => {
    api
      .get("/is-trial-end/", { signal: signal })
      .then(function (response) {
        if (response?.data?.status === true) {
          localStorage.clear();
          navigate("/login");
        }
      })
      .catch(function (error) {});
  };

  const getsubRoutes = (signal) => {
    api
      .get("/subcategory/", { signal: signal })
      .then(function (response) {
        dispatch(setSubCategory(response.data.data));
        localStorage.setItem("routes", JSON.stringify(response.data.data));
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    let mounted = true;
    const abortController = new AbortController();
    if (mounted) {
      const signal = abortController.signal;
      let token = getAuth();
      if (token) {
        setIsAuthenticated(true);
        getsubRoutes(signal);
      } else {
        setIsAuthenticated(false);
      }

      callIsTrialEnd(signal);
    }
    return () => {
      abortController.abort();
      mounted = false;
    };
  }, []);

  if (
    first_login === true &&
    location?.pathname?.split("/")?.pop() !== "complete-signup"
  ) {
    return <Navigate to="/complete-signup" />;
  }

  if (
    change_password !== null &&
    change_password !== undefined &&
    change_password !== "" &&
    change_password === true
  ) {
    return <Navigate to="/change-password" />;
  }

  if (isAuthenticated === null) {
    return <></>;
  }

  if (!isAuthenticated) {
    localStorage.setItem(
      "redirect_after_login",
      JSON.stringify(location?.pathname)
    );
  }

  return !isAuthenticated ? <Navigate to="/login" /> : <Outlet />;
};
export default PrivteRoutes;
