export const baseURL = process.env.REACT_APP_BASE_URL;

export const AZURE_ACCOUNT_NAME = process.env.REACT_APP_AZURE_ACCOUNT_NAME;

export const AZURE_CONTAINER_NAME = process.env.REACT_APP_AZURE_CONTAINER_NAME;

export const AZURE_CHART_CONTAINER_NAME =
  process.env.REACT_APP_AZURE_CHART_CONTAINER_NAME;

export const AZURE_MARKET_CONTAINER_NAME =
  process.env.REACT_APP_AZURE_MARKET_CONTAINER_NAME;

export const ALLOW_ORIGIN = process.env.REACT_APP_ALLOW_ORIGIN;

export const QLIK_HOST = process.env.REACT_APP_QLIK_HOST;

export const firebaseRedirectURL = process.env.REACT_APP_FIREBASE_REDIRECT_URL;

export const DOMAIN_REDIRECT_TARGET =
  process.env.REACT_APP_DOMAIN_REDIRECT_TARGET;

export const FIREBASE_CONFIG = process.env.REACT_APP_FIREBASE_CONFIG;

export const SHOW_QNA_SURVEY = process.env.REACT_APP_SHOW_QNA_SURVEY;

// INFRONT
export const MODULEDIRECTORYNAME =
  process.env.REACT_APP_INFRONT_MODULEDIRECTORYNAME;

export const CUSTOMERID = process.env.REACT_APP_INFRONT_CUSTOMERID;

export const LOGIN = process.env.REACT_APP_INFRONT_LOGIN;

export const PASSWORD = process.env.REACT_APP_INFRONT_PASSWORD;

export const APIKEY = process.env.REACT_APP_INFRONT_APIKEY;

export const getAuth = () => {
  let auth = JSON.parse(localStorage.getItem("auth"));
  return auth;
};

export const isRequired = (value) => {
  if (value === "" || value === undefined || value === null) {
    return true;
  }
};

export function validateEmail(email) {
  const emailRegx = new RegExp(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);
  return emailRegx.test(email) !== false;
}
export function isCheckLength(value, length) {
  if (value.length !== length) {
    return true;
  }
}

export function isCheckOnlyNumber(value) {
  const numberRegx = new RegExp(/^[0-9\b]+$/);
  return numberRegx.test(value) !== false;
}

export function isPassword(value) {
  let str = value;
  if (
    str.match(/[a-z]/g) &&
    str.match(/[A-Z]/g) &&
    str.match(/[0-9]/g) &&
    str.match(/[^a-zA-Z\d]/g) &&
    str.length >= 10
  ) {
    return true;
  }
  return false;
}

export function UTC_to_localtime(date) {
  const options = {
    hourCycle: "h24",
    hour: "2-digit",
    minute: "2-digit",
  };

  var dt = new Date(date);

  var local_time = dt?.toLocaleString("en-IN", options);

  return local_time;
}

export function groupBy(xs, key) {
  return xs.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
}

export function padTo2Digits(num) {
  return num.toString().padStart(2, "0");
}

export function formatDate(date) {
  return [
    date.getFullYear(),
    padTo2Digits(date.getMonth() + 1),
    padTo2Digits(date.getDate()),
  ].join("-");
}

export const prettyDateTimeFormat = (date) => {
  const date_arr = date.split("_");
  let completeDate = `${date_arr[0]}-${date_arr[1]}-${date_arr[2]} ${date_arr[3]}:${date_arr[4]}:${date_arr[5]}`;
  return completeDate;
};

export const prettyDateFormat = (date) => {
  if (date) {
    const date_arr = date.split("_");
    let completeDate = `${date_arr[0]}-${date_arr[1]}-${date_arr[2]}`;
    return completeDate;
  }
};

export function isTodayOrYesterDayForHeadline(key) {
  const options = {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  };

  var dt = new Date(key);

  var local_datetime = dt?.toLocaleString("en-IN", options);

  var local_date = local_datetime?.split(", ");

  var final_date = `${local_date?.[0]?.split("/")?.[2]}-${
    local_date?.[0]?.split("/")?.[1]
  }-${local_date?.[0]?.split("/")?.[0]}`;

  const today = new Date();

  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);

  const formatted_yesterday = formatDate(yesterday);
  const formatted_today = formatDate(today);

  if (final_date === formatted_today) {
    return "Today";
  } else if (final_date === formatted_yesterday) {
    return "Yesterday";
  } else {
    return final_date;
  }
}

export function UTC_to_localdate_latestreport(date) {
  const options = {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  };

  var dt = new Date(date);

  var local_datetime = dt?.toLocaleString("en-IN", options);

  var local_date = local_datetime?.split(", ");

  var final_date = `${local_date?.[0]?.split("/")?.[2]}-${
    local_date?.[0]?.split("/")?.[1]
  }-${local_date?.[0]?.split("/")?.[0]}`;

  return final_date;
}

export function IsTodayOrYesterDayLatestreports(key) {
  const months = {
    0: "Jan",
    1: "Feb",
    2: "Mar",
    3: "Apr",
    4: "May",
    5: "Jun",
    6: "Jul",
    7: "Aug",
    8: "Sep",
    9: "Oct",
    10: "Nov",
    11: "Dec",
  };

  const today = new Date();

  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);

  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);

  const formatted_yesterday = formatDate(yesterday);
  const formatted_today = formatDate(today);
  const formatted_tomorrow = formatDate(tomorrow);

  if (key === formatted_today) {
    return "Today";
  } else if (key === formatted_yesterday) {
    return "Yesterday";
  } else if (key === formatted_tomorrow) {
    return "Tomorrow";
  } else {
    let date = new Date(key);
    return date.getDate() + " " + months[date.getMonth()];
  }
}
