import React, { Suspense, lazy } from "react";

import { Route, Routes, BrowserRouter } from "react-router-dom";

import PrivteRoutes from "./PrivteRoutes";

//Helper
import { DOMAIN_REDIRECT_TARGET } from "./Utils";

import "bootstrap/dist/css/bootstrap.min.css";
import "./Assets/css/swiper-bundle.min.css";
import "./Assets/css/common.css";
import "./Assets/css/mini-event-calendar.min.css";
import customLoader from "./Assets/images/custom_loader.gif";
import "bootstrap/dist/js/bootstrap.bundle.min.js";

const Login = lazy(() => import("./Pages/Auth/Login"));
const Forgot = lazy(() => import("./Pages/Auth/ForgotPassword"));
const Reset = lazy(() => import("./Pages/Auth/ResetPassword"));
const ChangePass = lazy(() => import("./Pages/Auth/ChangePassword"));
const Trial = lazy(() => import("./Pages/Auth/Trial"));
const EditProfile = lazy(() => import("./Pages/EditProfile/Index"));
const Home = lazy(() => import("./Pages/Home/index"));
const SentimentSurvey = lazy(() => import("./Pages/SentimentSurvey/index"));
const Landing = lazy(() => import("./Pages/Landing/index"));
const DeleteAccountInfo = lazy(() => import("./Pages/DeleteAccountInfo/index"));
const Pricing = lazy(() => import("./Pages/Pricing/index"));
const Contact = lazy(() => import("./Pages/ContactUs/index"));
const About = lazy(() => import("./Pages/AboutUs/index"));
const Products = lazy(() => import("./Pages/Products/index"));
const Preference = lazy(() => import("./Pages/Preferences/index"));
const FirstLogin = lazy(() => import("./Pages/Firstlogin/index"));
const Gini = lazy(() => import("./Pages/Gini/index"));
const Module = lazy(() => import("./Pages/Module/index"));
const InfoContact = lazy(() => import("./Pages/InfoContact/index"));
const InfoLanding = lazy(() => import("./Pages/InfoCeras/index"));
const InfoProducts = lazy(() => import("./Pages/InfoProducts/index"));
const InfoPricing = lazy(() => import("./Pages/InfoPricing/index"));
const InfoTerms = lazy(() => import("./Pages/InfoTerms/index"));
const TermsAndCondition = lazy(() => import("./Pages/TermsAndCondition/index"));
const Privacy = lazy(() => import("./Pages/Privacy/index"));
const PageNotFound = lazy(() => import("./Pages/PageNotFound/index"));
const MobileInfront = lazy(() => import("./Pages/MobileInfront/index"));
const MobileFuturePricing = lazy(() => import("./Pages/MobileFuturePricing/index"));

function AppRoutes() {
  if (window.location.hostname !== DOMAIN_REDIRECT_TARGET) {
    console.log(`${window.location.hostname} is not equal to ${DOMAIN_REDIRECT_TARGET}`);
    console.log(`redirect to ${DOMAIN_REDIRECT_TARGET}`);
    let port = window.location.port ? ":" + window.location.port : ""; // in case the port is blanc don't add a :
    // combine protocol, // , (sub)domain, : , port and slug.
    // on the server the protocol happens to be limited to https. This is a server setting. Moreover, on the server the port is blank. For local protocol will be http and a port of 3000 will be added.
    // the solution is to take whatever was present in the url and only change the domain.
    window.location.href = window.location.protocol + "//" + DOMAIN_REDIRECT_TARGET + port + window.location.pathname;
  }
  return (
    <Suspense
      fallback={<img src={customLoader} className="custom-loader" alt="loading..." height="70px" width="70px" />}
    >
      <BrowserRouter>
        <Routes>
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/forgot" element={<Forgot />} />
          <Route exact path="/reset-password/:uid" element={<Reset />} />
          <Route exact path="/change-password" element={<ChangePass />} />
          <Route exact path="/trial" element={<Trial />} />

          <Route exact path="/" element={<Landing />} />

          <Route exact path="/contact-us" element={<Contact />} />
          <Route exact path="/sentimentsurvey" element={<SentimentSurvey />} />

          <Route exact path="/about-us" element={<About />} />
          <Route exact path="/products" element={<Products />} />
          <Route exact path="/pricing" element={<Pricing />} />
          <Route exact path="/terms" element={<TermsAndCondition />} />
          <Route exact path="/privacy" element={<Privacy />} />
          <Route exact path="/infront/mobile" element={<MobileInfront />} />
          <Route exact path="/infront/mobile/futurepricing" element={<MobileFuturePricing />} />
          <Route exact path="/delete-account-info" element={<DeleteAccountInfo />} />
          <Route path="/" element={<PrivteRoutes />}>
            <Route exact path="/dashboard" element={<EditProfile />} />
            <Route exact path="/home" element={<Home />} />
            <Route exact path="/preferences" element={<Preference />} />
            <Route exact path="/complete-signup" element={<FirstLogin />} />
            <Route exact path="/weather/:slug/:slug" element={<Module />} />
            <Route exact path="/crops/:slug/:slug" element={<Module />} />
            <Route exact path="/trade/:slug/:slug" element={<Module />} />
            <Route exact path="/policies/:slug/:slug" element={<Module />} />
            <Route exact path="/download/:slug/:slug" element={<Module />} />
            <Route exact path="/prices/:slug/:slug" element={<Module />} />
            <Route exact path="/price-analysis/:slug/:slug" element={<Module />} />
            <Route exact path="/the-big-picture/:slug/:slug" element={<Module />} />
            <Route exact path="/report-archive/:slug/:slug" element={<Module />} />
            <Route exact path="/sd/:slug/:slug" element={<Module />} />
            <Route exact path="/gini/:slug/:slug" element={<Gini />} />
            <Route exact path="/info/info-contact" element={<InfoContact />} />
            <Route exact path="/info/info-ceras-analytics" element={<InfoLanding />} />
            <Route exact path="/info/info-products" element={<InfoProducts />} />
            <Route exact path="/info/info-pricing" element={<InfoPricing />} />
            <Route exact path="/info/info-terms-of-use" element={<InfoTerms />} />
            <Route exact path="/404" element={<PageNotFound />} />
            <Route path="*" element={<PageNotFound />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </Suspense>
  );
}

export default AppRoutes;
