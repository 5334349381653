import Routes from "./Routes";
import ToasterComponent from "./Components/UI/Toaster";

import React, { useRef, useEffect } from "react";
import Cookies from "universal-cookie";

import { QLIK_HOST } from "./Utils/index";
import { getAnalytics, logEvent } from "firebase/analytics";

import ReactGA from "react-ga4";

export const RefContext = React.createContext();
export const LandingRefContext = React.createContext();

function App() {
  const weather_ref = useRef(null);
  const crop_ref = useRef(null);
  const snd_ref = useRef(null);
  const trade_ref = useRef(null);
  const agri_ref = useRef(null);
  const prices_ref = useRef(null);
  const price_analysis_ref = useRef(null);
  const picture_ref = useRef(null);
  const data_ref = useRef(null);
  const we_ref = useRef(null);
  const unique_ref = useRef(null);
  const offer_ref = useRef(null);
  const app_ref = useRef(null);
  const approach_ref = useRef(null);
  const keyproduct_ref = useRef(null);
  const compare_ref = useRef(null);
  const testimonials_ref = useRef(null);
  const contact_account_manager_ref = useRef(null);

  const cookies = new Cookies();
  const analytics = getAnalytics();
  const handleTabClose = (event) => {
    // To prevent the pop up "Leave site?" message
    event.stopImmediatePropagation();
    let qliksession = cookies.get("X-Qlik-Session-jwt");

    if (
      qliksession !== null &&
      qliksession !== undefined &&
      qliksession !== ""
    ) {
      fetch(`https://${QLIK_HOST}/qps/jwt/session/${qliksession}`, {
        method: "DELETE",
      }).then(() => {});
    }
  };

  useEffect(() => {
    window.addEventListener("beforeunload", handleTabClose);

    return () => {
      window.removeEventListener("beforeunload", handleTabClose);
    };
  }, []);

  return (
    <div>
      <RefContext.Provider
        value={{
          weather_ref: weather_ref,
          crop_ref: crop_ref,
          snd_ref: snd_ref,
          trade_ref: trade_ref,
          agri_ref: agri_ref,
          prices_ref: prices_ref,
          price_analysis_ref: price_analysis_ref,
          picture_ref: picture_ref,
          data_ref: data_ref,
          contact_account_manager_ref: contact_account_manager_ref,
        }}
      >
        <LandingRefContext.Provider
          value={{
            unique_ref: unique_ref,
            offer_ref: offer_ref,
            app_ref: app_ref,
            approach_ref: approach_ref,
            keyproduct_ref: keyproduct_ref,
            compare_ref: compare_ref,
            we_ref: we_ref,
            testimonials_ref: testimonials_ref,
          }}
        >
          <Routes />
          <ToasterComponent />
        </LandingRefContext.Provider>
      </RefContext.Provider>
    </div>
  );
}

export default App;
