import { createSlice } from "@reduxjs/toolkit";

export const categorySlice = createSlice({
  name: "category",
  initialState: {
    active_category: "",
    categories: [],
    subCategories: [],
    activeSubCategory: "",
    subSubCategories: [],
    activeSubSubCategory: "",
  },
  reducers: {
    setActiveCategory: (state, action) => {
      state.active_category = action.payload;
    },
    setActiveSubCategory: (state, action) => {
      state.activeSubCategory = action.payload;
    },
    setActiveSubSubCategory: (state, action) => {
      state.activeSubSubCategory = action.payload;
    },
    setSubCategory: (state, action) => {
      state.subCategories = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setActiveCategory,
  setActiveSubCategory,
  setActiveSubSubCategory,
  setSubCategory,
} = categorySlice.actions;

export default categorySlice.reducer;
