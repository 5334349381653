import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import store from "./Store/Store";
import { Provider } from "react-redux";
import ReactGA from "react-ga4";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {FIREBASE_CONFIG} from './Utils'

try{

  const firebaseConfig = JSON.parse(FIREBASE_CONFIG)
  
  const measurementId = firebaseConfig?.measurementId
  
  const firebaseApp = initializeApp(firebaseConfig);
  const firebaseAnalytics = getAnalytics(firebaseApp);
  
  
  ReactGA.initialize(measurementId);
}
catch(error){
  console.error("error in firebase config init",error);
}

export function logAnalyticEvent(eventObj) {
  ReactGA.event(eventObj);
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,

  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
