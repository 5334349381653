// Third party
import { combineReducers } from "redux";
import { configureStore } from "@reduxjs/toolkit";

// Reducer
import authReducer from "./Reducer/Authentication/Authentication";
import categoryReducer from "./Reducer/Category/category";
import uiReducer from "./Reducer/UiReducer/uireducer";

const reducer = combineReducers({
  authReducer,
  categoryReducer,
  uiReducer,
});

export default configureStore({
  reducer,
});
