import { createSlice } from "@reduxjs/toolkit";

export const authSlice = createSlice({
  name: "auth",
  initialState: {},
  reducers: {
    setAuthData: (state, action) => {
      state.auth = action.payload;
    },
    clearAuthData: (state) => {
      state.auth = {};
    },
    setUserUid: (state, action) => {
      state.useruid = action.payload;
    },
    setToken: (state, action) => {
      state.token = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setAuthData, clearAuthData, setUserUid, setToken } =
  authSlice.actions;

export default authSlice.reducer;
